"use strict";
// Bootstrap and mount the appointment Vue component

import { createApp } from 'vue';
import AppointmentScheduler from './appointment-scheduler.vue';

const el = document.getElementById('appointment-scheduler');
if (el) {
    // Get the data from the element
    const input_name = el.dataset.inputName;

    let raw_blocks;
    try {
        raw_blocks = JSON.parse(el.dataset.blocks);
    } catch (e) {
        console.error("Could not parse blocks JSON", e);
    }

    const selected = el.dataset.selected;

    // Create the Vue app, passing the parsed data as props
    const app = createApp(AppointmentScheduler, {
        input_name: input_name,
        raw_blocks: raw_blocks,
        selected: selected,
    });

    app.mount(el);

    if (process.env.NODE_ENV !== "production") {
        window.apmt = app;
    }
}
