// Password validation helpers using the account API
document.addEventListener('DOMContentLoaded', function () {
    const password1 = document.getElementById('id_password1');
    const password2 = document.getElementById('id_password2');
    const feedback = document.getElementById('password-validation-feedback');

    if (password1 && password2 && feedback) {
        let last_password1 = password1.value;
        let last_password2 = password2.value;
        let errors = [];

        function clearErrors() {
            errors = [];
            feedback.innerHTML = "";
            feedback.classList.add('field-errors');
            password1.classList.remove('is-invalid');
            password2.classList.remove('is-invalid');
            document.querySelectorAll('.passwordfield .field-errors').forEach((element) => {
                element.remove();
            });
        }

        function addError(error) {
            errors.push(error);
            updateErrors();
        }

        function addErrors(new_errors) {
            errors = errors.concat(new_errors);
            updateErrors();
        }

        function updateErrors() {
            if (errors.length > 0) {
                password1.classList.add('is-invalid');
                password2.classList.add('is-invalid');
                let list_html = "";
                for (let error of errors) {
                    list_html += `<li><img class="img-fluid" src="/static/icons/x.svg" alt="X" />${error}</li>`;
                }
                feedback.innerHTML = list_html;
            }
        }

        async function validatePasswords() {
            // Don't proceed if no password1
            if (!password1.value) return;

            // Don't proceed if the passwords didn't change
            if (password1.value === last_password1 && password2.value === last_password2) return;

            // Clear existing errors
            clearErrors();

            // Check if the passwords match
            if (password2.value && password1.value !== password2.value) {
                addError("The passwords do not match");
            }

            // Get all of the data filled out so far on the form
            const data = {
                password: password1.value,
                first_name: document.getElementById('id_first_name').value,
                last_name: document.getElementById('id_last_name').value,
                email: document.getElementById('id_email').value,
            };

            try {
                const response = await fetch(API_URLS['account-api:validate_password'], {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();
                if (responseData.status === "invalid") {
                    addErrors(responseData.messages);
                }
            } catch (error) {
                console.error("There was an error validating password", error);
            }

            // Update the last records
            last_password1 = password1.value;
            last_password2 = password2.value;
        }

        // Validate when field changes
        password1.addEventListener('change', validatePasswords);
        password2.addEventListener('change', validatePasswords);
    }
});
