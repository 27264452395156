document.addEventListener('DOMContentLoaded', () => {
  const phoneInput = document.getElementById('id_phone');
  const phoneNumberInput = document.getElementById('id_phone_number');
  const userPhoneInput = document.getElementById('id_user-phone');
  const userProfilePhoneInput = document.getElementById('id_profile-0-phone')

  // Function to add event listener to an input field
  const addInputEventListener = (inputField) => {
    if (inputField) { // Check if the element exists
      inputField.addEventListener('input', function (input) {
        // Use a regular expression to remove all non-digit characters from the input
        // Then, separate the string into groups for a phone number format
        const match = input.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        // Format the value of the input field based on the captured groups
        // If the second group is not present, only use the first group
        // Otherwise, format the string as a phone number "(XXX) XXX-XXXX"
        input.target.value = !match[2]
          ? match[1]
          : '(' + match[1] + ') ' + match[2] + (match[3] ? '-' + match[3] : '');
      });
    }
  };

  // Add event listener to both input fields
  addInputEventListener(phoneInput);
  addInputEventListener(phoneNumberInput);
  addInputEventListener(userPhoneInput);
  addInputEventListener(userProfilePhoneInput);
});

document.addEventListener('DOMContentLoaded', () => {
  const inputField = document.getElementById('id_email_or_phone');
  const phoneFormat = '(###) ###-####';

  const applyPhoneMask = (input) => {
    const digits = input.replace(/\D/g, '');
    if (digits.length > 10) { // Early exit if more than 10 digits, likely transitioning to email
      return input;
    }
    let formatted = '';
    let digitIndex = 0;

    for (let i = 0; i < phoneFormat.length && digitIndex < digits.length; i++) {
      if (phoneFormat[i] === '#') {
        formatted += digits[digitIndex++];
      } else {
        formatted += phoneFormat[i];
      }
    }

    return formatted;
  };

  const detectInputTypeAndFormatAccordingly = (input) => {
    // Check for a clear indication of an email
    if (input.includes('@') || /[a-zA-Z+]/.test(input)) {
      // Likely an email, remove phone formatting
      return input.replace(/[^\d\w@.+]/g, '');
    } else {
      // Otherwise, attempt to apply phone mask
      return applyPhoneMask(input);
    }
  };

  if(inputField){
    inputField.addEventListener('input', (event) => {
      event.target.value = detectInputTypeAndFormatAccordingly(event.target.value);
    });
  }
});
