export async function updateServiceSummary(selectedService, availableServices) {
  selectedService.addEventListener('click', (e) => {
    if (e.target.tagName === 'INPUT') {
      var serviceId = e.target.value;
      var service = availableServices.find(s => s.id == serviceId);
      if (!['every 3 months', 'every 6 months'].includes(service.billing_period_type))
        document.querySelectorAll('#service_billing_period_type_display').forEach(
          el => el.textContent = `${service.billing_period_type_display}-to-${service.billing_period_type_display}`
        );
      else
        document.querySelectorAll('#service_billing_period_type_display').forEach(
          el => el.textContent = service.billing_period_type
        );
    }
  });
}
