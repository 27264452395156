function filterServices(availableServices, building_id, unit_id) {
    let filteredServices = [];

    if (unit_id) {
        filteredServices = availableServices.filter(service => service.unit_id === unit_id);
    }

    if (filteredServices.length === 0 && building_id) {
        filteredServices = availableServices.filter(service => service.building_id === building_id);
    }

    if (filteredServices.length === 0) {
        filteredServices = availableServices.filter(service => !service.building_id && !service.unit_id);
    }

    return filteredServices;
}

// Method to generate service options HTML
export function generateServiceOptions(availableServices, building_id, unit_id) {
    const filteredServices = filterServices(availableServices, building_id, unit_id);

    return filteredServices
        .map((service, index) => `
            <div class="service-box-container service-box-container-hover relative" data-service-box="">
                <input type="radio" name="selected_service" value="${service.id}" required="" id="id_selected_service_${index}">
                <div class="background-container"></div>
                <label for="id_selected_service_${index}" class="service-box-label">
                    <div class="service-box__upto">
                        <span>Up to</span>
                    </div>
                    <div class="service-box-price">
                        <div>
                            <span>${service.max_bandwidth}</span>
                            <span class="service-box-bandwidth-unit">Mbps</span>
                        </div>
                        <div class="service-box-price__container">
                            <span class="service-box-price-currency">$</span>
                            <span>${parseInt(service.price)}</span>
                            <small>/ ${service.billing_period_type_display.charAt(0).toUpperCase() + service.billing_period_type_display.slice(1)}</small>
                        </div>
                    </div>
                </label>
            </div>
        `).join('');
}

export function generateServiceSelectOptions(availableServices, building_id, unit_id) {
    const filteredServices = filterServices(availableServices, building_id, unit_id);

    const options = filteredServices
        .map((service, index) => `
            <option value="${service.id}">$${parseInt(service.price)} per ${service.billing_period_type_display}, up to ${service.max_bandwidth} Mbps</option>
        `).join('');

    return `<option value selected>---------</option>${options}`;
}

export function generateUnitSelectOptions(availableUnits, building_id) {
    const options = availableUnits
        .filter(unit => unit.building_id === building_id)
        .map(unit => `
            <option value="${unit.id}">${unit.name}</option>
        `).join('');

    return `<option value selected>---------</option>${options}`;
}
