"use strict";

document.addEventListener("DOMContentLoaded", function () {
    const pronouns = document.querySelector('#id_pronouns, #id_user-pronouns, #id_profile-pronouns');
    const pronounsOtherWrapper = document.getElementById('pronouns-other');
    const pronounsOtherInput = document.querySelector('#id_pronouns_other, #id_user-pronouns_other, #id_profile-pronouns_other');
    const pronounsOtherLabel = document.querySelector('label[for=id_pronouns_other], label[for=id_user-pronouns_other], label[for=id_profile-pronouns_other]');

    if (pronouns && pronounsOtherWrapper && pronounsOtherInput && pronounsOtherLabel) {
        function togglePronounsOtherVisibility() {
            if (pronouns.value === 'other') {
                pronounsOtherWrapper.style.display = 'block';
                pronounsOtherInput.required = true;
                pronounsOtherLabel.innerHTML = 'My preferred pronouns';
            } else {
                pronounsOtherWrapper.style.display = 'none';
                pronounsOtherInput.required = false;
            }
        }

        pronouns.addEventListener('change', togglePronounsOtherVisibility);
        togglePronounsOtherVisibility();
    }
});
