<template>
    <div class="appointment-scheduler">
        <div class="row">
            <div class="col-md">
                <div class="form-floating mb-3 border border-secondary rounded">
                    <select id="day-select" class="form-control" name="day_select" v-model="selected_day">
                      <option v-for="day of days_formatted" :value="day.key" :key="day.key">
                        {{ day.value }}
                      </option>
                    </select>
                    <label class="label" for="day-select">
                        Day
                    </label>
                </div>
            </div>
            <div class="col-md">
                <div class="form-floating mb-3 border border-secondary rounded">
                    <select id="block-select" class="form-select" :name="this.input_name" v-model="selected_block" :disabled="!is_day_selected">
                        <option v-for="block of blocks_for_selected_day" :value="block.key">
                            {{formatStart(block.start)}} to {{formatEnd(block.end)}}
                        </option>
                    </select>
                    <label class="label" for="block-select">
                        Time
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: ['input_name', 'raw_blocks', 'selected'],
        data() {
            return {
                today: new Date().toISOString(),
                blocks: {},
                selected_day: undefined,
                selected_block: undefined,
            }
        },
        computed: {
            days() {
                const days = [];
                for (const day_str of Object.keys(this.blocks).sort()) {
                    let day = moment(`${day_str}T00:00:00`);
                    days.push(day.toDate());
                }
                return days;
            },
            days_formatted() {
                const days = [];
                for (const day of this.days) {
                    days.push({
                        'key': moment(day).format('YYYY-MM-DD'),
                        'value': moment(day).format('MMM Do, YYYY - dddd')
                    })
                }
                return days;
            },
            blocks_for_selected_day() {
                return this.blocks[this.selected_day];
            },
            is_day_selected() {
                return !!this.selected_day;
            },
        },
        methods: {
            formatStart(start) {
                return moment(start).format("hh:mm a");
            },
            formatEnd(end) {
                return moment(end).format("hh:mm a");
            },
            loadBlocks() {
                // Parse the JSON data into a usable data structure, localized to the browser timezone
                for (let [day_str, day_blocks] of Object.entries(this.raw_blocks)) {
                    this.blocks[day_str] = [];
                    for (const day_block of day_blocks) {
                        this.blocks[day_str].push({
                            start: moment.parseZone(day_block.start),
                            end: moment.parseZone(day_block.end),
                            key: day_block.key,
                        })
                    }
                }
            },
            loadSelected() {
                if (this.selected) {
                    let [start, end] = this.selected.split('to');
                    this.selected_day = moment.parseZone(start).format('YYYY-MM-DD');
                    this.selected_block = this.selected;
                }
            },
        },
        beforeMount() {
            this.loadBlocks();
            this.loadSelected();
        },
    }
</script>
