function servicePrice(price) {
  const servicePriceElement = document.querySelector('#service_price');
  if (price === "0.00") {
    servicePriceElement.classList.add('bbl-price-notice');
    return "Contact Property Management";
  }
  servicePriceElement.classList.remove('bbl-price-notice');
  return `$ ${price}`;
}

function updateBroadbandLabel(service, broadbandLabel) {
  const serviceBillingPeriodType = service.billing_period_type.charAt(0).toUpperCase() + service.billing_period_type.slice(1);
  if (broadbandLabel.hidden) {
    broadbandLabel.hidden = false;
  }
  document.querySelector('#service_name').textContent = service.name;
  document.querySelectorAll('#service_billing_period_type').forEach(el => el.textContent = serviceBillingPeriodType);
  document.querySelector('#service_price').textContent = servicePrice(service.price);
  document.querySelectorAll('#service_speed').forEach(el => el.textContent = `${service.max_bandwidth} Mbps`);
  document.querySelector('#service_id').textContent = service.fcc_id;
}

export async function displayBroadbandLabel(selectedService, availableServices, eventName) {
  const broadbandLabel = document.querySelector('#broadband-label');

  const handleEvent = (e) => {
    const serviceId = e.target.value;
    const service = availableServices.find(s => s.id == serviceId);
    if (service) {
      updateBroadbandLabel(service, broadbandLabel);
    } else {
      broadbandLabel.hidden = true;
    }
  };

  if (eventName === 'click') {
    selectedService.addEventListener('click', (e) => {
      if (e.target.tagName === 'INPUT') {
        handleEvent(e);
      }
    });
  } else if (eventName === 'change') {
    selectedService.addEventListener('change', handleEvent);
  }
}

export async function initBroadbandLabel(availableServices) {
  const selectedService = document.querySelector('#id_selected_service');
  const broadbandLabel = document.querySelector('#broadband-label');

  const updateLabel = (serviceId) => {
    const service = availableServices.find(s => s.id == serviceId);
    if (service) {
      updateBroadbandLabel(service, broadbandLabel);
    } else {
      broadbandLabel.hidden = true;
    }
  };

  updateLabel(selectedService.value);
  selectedService.addEventListener('change', (e) => {
    updateLabel(e.target.value);
  });
}
