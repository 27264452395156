// Slide between elements in sets with options for animated or scrubbed via scroll bar. This allows you to create any
// number of sets of elements that will slide between each other. The sets can contain any number of elements, but must
// be the same number in each set. The slider type can be animated or controlled by the scroll position, and there are
// several options for each type of slider. The minimum number of children in every set is 2.
//
// Animated Slider:
//
// This will animate forward between each element in all sets. Reverse direction is not supported.
//
// Animated Usage:
//
//     <div data-slider="animate" data-slider-delay="0.5" data-slider-repeat="-1" data-slider-interval="5"
//          data-slider-duration="0.2" data-slider-trigger="top bottom">
//         <div data-slider-set data-slider-prev-direction="right" data-slider-next-direction="right">
//             <div>Some text</div>
//             <div>Some text</div>
//             <div>Some text</div>
//         </div>
//         <div data-slider-progress></div>
//     </div>
//
// data-slider:          The type of slider: 'animate' or 'scroll'. 'animate' in this case.
// data-slider-delay:    How long to wait before starting the animation.
//                       Default: 0
// data-slider-repeat:   How many times to repeat the animation.
//                       Default: -1 (forever)
// data-slider-interval: How long to wait between each slide.
//                       Default: 5
// data-slider-duration: How long to animate each slide transition. Best if kept short.
//                       Default: 0.2
// data-slider-trigger:  If set, the animation will be triggered when the element is scrolled to the given position
//                       Must be "<trigger> <scroller>" such as "top bottom" to trigger when the top of the element
//                       meets the bottom of the scroll bar.
//
// Scroll Slider:
//
// This will tie the animation to the scroll bar. By default, it will not pin the element (so that it stays in place),
// and instead will just play the animation once the element is fully visible at the bottom of the viewport, then finish
// it when the element touches the top of the viewport. The other option is to "pin" the element so that it stays in
// one place while the animation plays. This requires some careful set up of elements around the slider so that large
// blank areas don't become visible during the scroll. (See GSAP docs on pinning, specifically the pin spacer.)
//
// Scroll Usage:
//
//     <div data-slider="scroll" data-slider-pin="true" data-slider-trigger="center center" data-slider-length="500"
//          data-slider-duration="0.2">
//         <section class="something-else-to-stay-on-screen"></section>
//         <section class="my-sliding-section">
//             <div data-slider-set data-slider-next-direction="none">
//                 <div>Some text</div>
//                 <div>Some text</div>
//                 <div>Some text</div>
//             </div>
//             <div data-slider-progress></div>
//         </section>
//         <section class="something-else-to-stay-on-screen"></section>
//     </div>
//
// data-slider:          The type of slider: 'animate' or 'scroll'. 'scroll' in this case.
// data-slider-pin:      If set, the element will be pinned in place while the animation plays.
//                       Default: false
// data-slider-trigger:  If set, the animation will be triggered when the element is scrolled to the given position
//                       Must be "<trigger> <scroller>" such as "bottom bottom" to trigger when the bottom of the
//                       element touches the bottom of the scroll bar.
//                       Default if not pinned: "bottom bottom".
//                       Default if pinned: "center center".
// data-slider-length:   The length of the animation in pixels scrolled, per set item. No effect if not pinned.
//                       Default: 500
// data-slider-duration: How long to animate each slide transition. Best if kept short.
//                       Default: 0.2
// data-slider-debug:    If set, will show markers for the element and scroller.
//                       Default: false
//
// Slider set options:
//
// Each `data-slider-set` element can have the following options. It works best to have them match, or 'prev' set to a
// direction and 'next 'set to "none" so that it is just revealed behind the previous item.
//
// data-slider-prev-direction: The direction to slide the prev element ("up", "down", "left", "right", "none")
//                             Default: "up"
// data-slider-next-direction: The direction to slide the next element ("up", "down", "left", "right", "none")
//                             Default: "up"
//
// Progress indicator
//
// You must place a div with `data-slider-progress` inside the slider element to create a progress indicator,
// which will visually show the current progress of slides in all the sets. This works for both animated and scrolled
// sliders.
//
//     <div className="slider-progress-group" data-slider-progress></div>
//
// Notes on styling:
//
// Each pertinent element will have the following classes added to it upon initialization. To prevent layout reflows,
// it is suggested that you prime these classes in the HTML.
//
//     "slider-set": applied to each element with `data-slider-set`
//     "slider-set-item": applied to each child of `data-slider-set`
//     "active": applied to the active slider-set-item (usually the first)
//     "slider-progress-group": applied to the element with 'data-slider-progress'
//     "slider-progress-indicator": applied to each created child element of the slider-progress-group
//
// Each set will be set to `overflow: hidden` so that animating elements will not be visible outside the parent.
// The slider will maintain the document flow by always having the active set item set to "position: relative". Other
// items will be set to "position: absolute" with a negative z-index and opacity set to 0.
//
// So the stack will always resemble:
//
//     <div data-slider="animate">
//         <div class="slider-set" data-slider-set>
//             <div class="slider-set-item active">...</div>   (position: relative; opacity: 1; z-index: 1)
//             <div class="slider-set-item">...</div>          (position: absolute; opacity: 0; z-index: -1)
//             <div class="slider-set-item">...</div>          (position: absolute; opacity: 0; z-index: -1)
//             <div class="slider-set-item">...</div>          (position: absolute; opacity: 0; z-index: -1)
//         </div>
//     </div>
//
// It's important that each set item be the same height. `slider-set-item` will apply `white-space: nowrap` to each
// item, but that may not be enough to control the height. If there are strange layout issues, then you may need to
// explicitly set the height of each item.


import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', function() {
    const elements = document.querySelectorAll('[data-slider]');

    for (const element of elements) {
        slider(element);
    }
});

function slider(element) {
    const type = element.dataset.slider || 'scroll';
    const delay = element.dataset.sliderDelay || 0;
    const repeat = element.dataset.sliderRepeat !== undefined ? parseInt(element.dataset.sliderRepeat) : -1;
    const interval = parseFloat(element.dataset.sliderInterval) || 5;
    const duration = parseFloat(element.dataset.sliderDuration) || 0.2;
    const trigger = element.dataset.sliderTrigger || null;
    const pin = ['true', '1', 'yes', 'on'].includes(element.dataset.sliderPin) || false;
    const length = parseInt(element.dataset.sliderLength) || 500;
    const debug = ['true', '1', 'yes', 'on'].includes(element.dataset.sliderDebug) || false;

    // Sanity check options
    if (interval < duration) {
        console.error(`data-slider-interval must be greater than data-slider-duration.`);
        return;
    }

    // Find all sets
    const sets = findSets(element);
    // console.log("sets", sets);

    // Make sure there's at least 1 set
    if (sets.length === 0) {
        console.error(`Slider must have at least 1 set marked with 'data-slider-set'`);
        return;
    }

    // Check that all sets have the same number of children
    let lastCount = null;
    for (const set of sets) {
        if (lastCount === null) {
            lastCount = set.children.length;
        } else if (lastCount !== set.children.length) {
            console.error(`Slider sets must have the same number of children`);
            return [];
        }
    }

    // Create the main timeline
    let masterTimeline;
    if (type === "animate") {
        const vars = {
            delay: delay,
            repeat: repeat,
        };
        if (trigger) {
            vars.scrollTrigger = {
                trigger: element,
                start: trigger,
            };
        }
        masterTimeline = gsap.timeline(vars);
    } else if (type === "scroll") {
        let start = trigger;
        if (!start) {
            if (pin) {
                start = "center center"
            } else {
                start = "bottom bottom";
            }
        }
        let end;
        if (pin) {
            end = `+=${length * sets[0].children.length}`;
        } else {
            end = `+=${window.innerHeight - element.offsetHeight}`;
        }
        masterTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                start: start,
                end: end,
                scrub: true,
                pin: pin,
                markers: debug,
            },
        });
    } else {
        console.error(`Unknown slider type: ${type}`);
        return;
    }

    // Create the progress indicators, if there's a `data-slider-progress` element
    const progressElement = element.querySelector('[data-slider-progress]');
    if (progressElement) {
        progressElement.classList.add('slider-progress');

        for (let i = 0; i < sets[0].children.length; i++) {
            const indicator = document.createElement('div');
            indicator.classList.add('slider-progress-indicator');
            progressElement.appendChild(indicator);

            // Add it to the timeline
            masterTimeline.to(indicator, {
                '--progress': "100%",
                ease: "none",
                duration: interval,
            });
        }
    }

    // Look for a kinetoscope
    const kinetoscope = element.querySelector('[data-slider-kinetoscope]');
    let kinetoscopeCallback = null;
    if (kinetoscope) {
        const fileTemplate = kinetoscope.dataset.sliderKinetoscope;
        const frameMin = parseInt(kinetoscope.dataset.sliderKinetoscopeMin) || 0;
        const frameMax = parseInt(kinetoscope.dataset.sliderKinetoscopeMax) || 0;
        const context = kinetoscope.getContext('2d');

        if (!fileTemplate) {
            console.error(`data-slider-kinetoscope requires a file template`);
            return;
        }
        const fileTemplateDecoded = fileTemplate.replace(/%23/g, "#");

        // Sanity check
        if (frameMin >= frameMax) {
            console.error(`data-slider-kinetoscope-min must be less than data-slider-kinetoscope-max`);
            return;
        }
        const frameCount = frameMax - frameMin + 1;

        // Deconstruct the file template
        const frameDigits = fileTemplateDecoded.lastIndexOf("#") - fileTemplateDecoded.indexOf("#") + 1;

        if (frameDigits <= 0) {
            console.error(`data-slider-kinetoscope must contain at least 1 '#' character`);
            return;
        }

        const fileTemplateParts = fileTemplateDecoded.split("#".repeat(frameDigits));

        if (fileTemplateParts.length !== 2) {
            console.error(`data-slider-kinetoscope must contain only one section of '#' characters`);
            return;
        }
        const frameFilePrefix = fileTemplateParts[0];
        const frameFileSuffix = fileTemplateParts[1];

        // Preload the frames
        const images = [];
        for (let i = 0; i < frameCount; i++) {
            const frameNumber = frameMin + i;
            const frameNumberPadded = frameNumber.toString().padStart(frameDigits, "0");
            const frameFile = `${frameFilePrefix}${frameNumberPadded}${frameFileSuffix}`;
            const img = new Image();
            img.src = frameFile;
            images.push(img);
        }
        // Update the kinetoscope to the appropriate frame on every update of the master timeline.
        // The frame shown should be proportional to the progress of the master timeline.
        const render = (i) => {
            context.clearRect(0, 0, kinetoscope.width, kinetoscope.height);
            context.drawImage(images[i], 0, 0, kinetoscope.width, kinetoscope.height);
        }

        images[0].onload = () => { render(0); }

        kinetoscopeCallback = () => {
            const timelineProgress = masterTimeline.progress();
            const frameIndex = Math.floor(timelineProgress * images.length);

            // Ignore if the animation goes past the last frame
            if (frameIndex < 0 || frameIndex >= images.length) {
                return;
            }

            // Draw the frame
            render(frameIndex);
        };
    }

    // Animate each set change when we reach the appropriate time in the timeline
    let prevSetItemIndex = 0;
    masterTimeline.eventCallback("onUpdate", () => {
        const timelineProgress = masterTimeline.progress();
        const snapInterval = 1 / sets[0].children.length;
        const setItemIndex = Math.floor(timelineProgress / snapInterval);  // The current set item index
        // const setItemProgress = (timelineProgress % snapInterval) / snapInterval;  // The progress of the set item
        // console.log("progress", timelineProgress, setItemIndex, setItemProgress);

        // Ignore if the animation goes past the last set item
        if (setItemIndex >= sets[0].children.length) {
            return;
        }

        if (setItemIndex !== prevSetItemIndex) {
            // console.log("Set Item change", prevSetItemIndex, setItemIndex);
            animateSetChange(sets, prevSetItemIndex, setItemIndex, duration);
            prevSetItemIndex = setItemIndex;
        }

        if (kinetoscopeCallback) {
            kinetoscopeCallback();
        }
    });
}

function animateSetChange(sets, prevIdx, nextIdx, duration) {
    // Temporarily set the height of each set element so we don't reflow while swapping classes around
    for (const set of sets) {
        set.element.style.height = `${set.element.offsetHeight}px`;
    }

    // Animate the previous children out, remove the active class
    for (const set of sets) {
        const prev = set.children[prevIdx];

        // Since we will remove the 'active' class, we need to override z-index and opacity during the animation
        gsap.set(prev, {
            zIndex: 10,
            opacity: 1,
        })

        // Remove active class
        prev.classList.remove('active');

        // Animate the previous child out then reset the direct styles
        gsap.to(prev, {
            ...directionToPropertyTo(set.prevDirection),
            duration: duration,
            onComplete: () => {
                gsap.set(prev, {
                    zIndex: null,
                    yPercent: null,
                    opacity: null,
                });
            }
        });
    }

    // Animate the next children in, add the active class
    for (const set of sets) {
        const next = set.children[nextIdx];
        next.classList.add('active');
        gsap.from(next, {
            ...directionToPropertyFrom(set.nextDirection),
            duration: duration,
            onComplete: () => {
                gsap.set(next, {
                    yPercent: null,
                });
            }
        });
    }

    // Reset the set element heights
    for (const set of sets) {
        set.element.style.height = null;
    }
}

function findSets(element) {
    // Keep track of all sets and their individual options
    const sets = [];

    // Find all sets defined with `data-slider-set` attribute
    const setElements = element.querySelectorAll('[data-slider-set]');
    for (const setElement of setElements) {
        const prevDirection = setElement.dataset.sliderPrevDirection || 'up';
        const nextDirection = setElement.dataset.sliderNextDirection || 'up';

        // Make sure this element is relative positioned and overflow is hidden
        setElement.classList.add('slider-set');

        // Make sure there are at least 2 elements
        const children = setElement.children;
        if (children.length < 2) {
            console.error(`Slider sets must have at least 2 children`);
            continue;
        }

        // Make sure the children have the proper classes. Set the first as 'active'.
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            child.classList.add('slider-set-item');
            if (i === 0) {
                child.classList.add('active');
            }
        }

        // Add this set to the list of sets
        sets.push({
            'element': setElement,
            'children': setElement.children,
            'prevDirection': prevDirection,
            'nextDirection': nextDirection,
        });
    }

    return sets;
}

function directionToPropertyTo(direction) {
    switch (direction) {
        case 'up':
            return {yPercent: -100};
        case 'down':
            return {yPercent: 100};
        case 'left':
            return {xPercent: -100};
        case 'right':
            return {xPercent: 100};
        default:
            return {};
    }
}

function directionToPropertyFrom(direction) {
    switch (direction) {
        case 'up':
            return {yPercent: 100};
        case 'down':
            return {yPercent: -100};
        case 'left':
            return {xPercent: 100};
        case 'right':
            return {xPercent: -100};
        default:
            return {};
    }
}
