"use strict";

const questionnaire = document.getElementById('id_questionnaire');
const questionnaireReferralWrapper = document.getElementById('questionnaire-referral');
const questionnaireReferralInput = document.getElementById('id_referred_by_code');
const questionnaireOtherWrapper = document.getElementById('questionnaire-other');
const questionnaireOtherInput = document.getElementById('id_questionnaire_other');

if (questionnaire && questionnaireReferralWrapper && questionnaireReferralInput && questionnaireOtherWrapper && questionnaireOtherInput) {
    function toggleQuestionnaireReferralVisibility() {
        if (questionnaire.value === 'referral') {
            questionnaireReferralWrapper.style.display = 'block';
        } else {
            questionnaireReferralWrapper.style.display = 'none';
            questionnaireReferralInput.value = "";
        }
    }

    questionnaire.addEventListener('change', toggleQuestionnaireReferralVisibility);
    document.addEventListener("DOMContentLoaded", toggleQuestionnaireReferralVisibility);

    function toggleQuestionnaireOtherVisibility() {
        if (questionnaire.value === 'other' || questionnaire.value === 'ambassador') {
            questionnaireOtherWrapper.style.display = 'block';
            questionnaireOtherInput.required = true;
            if (questionnaire.value === 'other') {
                questionnaireOtherInput.placeholder = "Please tell us more...";
            } else if (questionnaire.value === 'ambassador') {
                questionnaireOtherInput.placeholder = "Please enter Ambassador's name...";
            }
        } else {
            questionnaireOtherWrapper.style.display = 'none';
            questionnaireOtherInput.required = false;
        }
    }

    questionnaire.addEventListener('change', toggleQuestionnaireOtherVisibility);
    document.addEventListener("DOMContentLoaded", toggleQuestionnaireOtherVisibility);
}
