const activate_now_choice = document.getElementById('id_installation-activate_now_0');
const activate_later_choice = document.getElementById('id_installation-activate_now_1');
const auto_activation_date_wrapper = document.getElementById('auto_activation_date');
const auto_activation_date_input = document.getElementById('id_auto_activation_date') || document.getElementById('id_installation-auto_activation_date');


if (activate_now_choice && activate_later_choice && auto_activation_date_wrapper && auto_activation_date_input) {
  function toggleAutoActivationDateChooserVisibility() {
    if (activate_later_choice.checked) {
      auto_activation_date_wrapper.classList.add('show');
      auto_activation_date_input.required = true;
    } else {
      auto_activation_date_wrapper.classList.remove('show');
      auto_activation_date_input.required = false;
    }
  }

  activate_now_choice.addEventListener('change', toggleAutoActivationDateChooserVisibility);
  activate_later_choice.addEventListener('change', toggleAutoActivationDateChooserVisibility);
  document.addEventListener("DOMContentLoaded", toggleAutoActivationDateChooserVisibility);
}
