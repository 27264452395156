// Detects when the user scrolls and adds a class to the element.
//
// Usage:
//
//      <div data-scroll-detect-px="x,y" data-scroll-detect-class="scrolled,scrolled-far" />
//
//  data-scroll-detect-px:    comma separated list of distances (in pixels) that the user needs to scroll before adding
//                            the corresponding class to the element
//  data-scroll-detect-class: comma separated list of classes to add to the element which correspond to the distances
//                            in data-scroll-detect-px

document.addEventListener('DOMContentLoaded', function() {
    const elements = document.querySelectorAll('[data-scroll-detect-px]');

    [...elements].forEach(function(element) {
        const distances = element.dataset.scrollDetectPx.split(',')
            .map((x) => parseInt(x, 10));
        const classes = element.dataset.scrollDetectClass.split(',');

        if (distances.length !== classes.length) {
            console.error('data-scroll-detect-px and data-scroll-detect-class must have the same number of values');
            return;
        }

        // console.debug("Scroll detect on", element, `with distances ${distances} and classes ${classes}`);

        let lastScrollPosition = 0;
        let busy = false;

        function updateScrollClasses() {
            const scrollPosition = window.scrollY;

            for (let i = 0; i < distances.length; i++) {
                const distance = distances[i];
                const className = classes[i];

                if (scrollPosition > distance) {
                    element.classList.add(className);
                } else {
                    element.classList.remove(className);
                }
            }
        }

        function scrollCallback() {
            lastScrollPosition = window.scrollY;

            if (!busy) {
                window.requestAnimationFrame(function() {
                    updateScrollClasses();
                    busy = false;
                });

                busy = true;
            }
        }

        document.addEventListener('scroll', scrollCallback);
        scrollCallback();
    });
});
