// Helpers for billing pages
import { loadStripe } from '@stripe/stripe-js';


function disable_billing_buttons(status = true) {
    const buttons = document.querySelectorAll('.js-billing-button');
    for (const button of buttons) {
        button.disabled = status;
    }
}

// Initialize billing functionality. This requires an instance of a Stripe object initialized like:
//     const stripe = await loadStripe('{{ STRIPE_PUBLIC_KEY }}');
export async function initBilling() {
    const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
    const elements = stripe.elements();

    const style = {
        base: {
            fontSize: '1.1em',
            color: '#495057',
        }
    };

    const card = elements.create('card', { style });
    card.mount('#card-element');

    // Get elements
    const form = document.getElementById('payment-form');
    const cardErrors = document.getElementById('card-errors');

    // Communicate errors
    card.addEventListener('change', function (e) {
        if (e.error) {
            cardErrors.textContent = e.error.message;
        } else {
            cardErrors.textContent = '';
        }
    });

    form.addEventListener('submit', async function (e) {
        e.preventDefault();
        disable_billing_buttons(true);

        const name = document.getElementById('id_card_name').value;
        const cardData = {
            name: name
        };

        try {
            const result = await stripe.createToken(card, cardData);
            if (result.error) {
                cardErrors.textContent = result.error.message;
                disable_billing_buttons(false);
            } else {
                // Insert the token ID into the form so it gets submitted to the server
                const hiddenInput = document.createElement('input');
                hiddenInput.setAttribute('type', 'hidden');
                hiddenInput.setAttribute('name', 'stripe_token');
                hiddenInput.setAttribute('value', result.token.id);
                form.appendChild(hiddenInput);

                // Submit the form
                form.submit();
            }
        } catch (error) {
            console.error('Error creating token:', error);
            cardErrors.textContent = 'An error occurred while processing your payment.';
            disable_billing_buttons(false);
        }
    });

    // Handle the billing address same checkbox
    const same_element = document.querySelector('#id_billing_address_same, #id_pm-billing_address_same');
    const billing_address_form = document.getElementById("billing-address-form");

    function update_billing_address_fields_required(value) {
        for (let prefix of ['', 'pm-']) {
            for (let selector of ['address_line1', 'city', 'state', 'zip_code']) {
                const fullName = `id_${prefix}${selector}`;
                const element = document.getElementById(fullName);
                if (element) {
                    element.required = value;
                }
            }
        }
    }

    function update_billing_address_form() {
        if (same_element.checked) {
            billing_address_form.classList.remove('show');
            billing_address_form.classList.add('hide');
            update_billing_address_fields_required(false);
        } else {
            billing_address_form.classList.remove('hide');
            billing_address_form.classList.add('show');
            update_billing_address_fields_required(true);
        }
    }

    if (same_element) {
        update_billing_address_form();
        same_element.addEventListener("change", update_billing_address_form);
    } else {
        billing_address_form.classList.remove('collapse');
    }
}

export function waitForStripe(id) {
    // Check the Billing API periodically to see if we're still waiting for Stripe, reloads the page once we're not.
    console.debug("Waiting for Stripe", id);

    let invocations = 0;
    let still_waiting = true;
    let url;
    if (id) {
        url = API_URLS['billing-api:waitforstripe_by_id'].replace('__ID__', id);
    } else {
        url = API_URLS['billing-api:waitforstripe'];
    }

    function checkStripe() {
        invocations++;
        console.debug(`Calling WaitForStripe API. Invocations: ${invocations}`);
        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.waiting_for_stripe === false) {
                    window.location.reload();
                    still_waiting = false;
                }
            })
            .catch(error => {
                console.error("Got an error checking stripe status: " + error);
            })
            .finally(() => {
                if (still_waiting) {
                    // Backoff .25 seconds each iteration, maximum of 5 second timeout
                    const timeout = Math.min(5000, 1000 + 1000 * ((invocations - 1) / 4));
                    console.debug(`Waiting ${timeout} milliseconds`);
                    setTimeout(checkStripe, timeout);
                }
            });
    }

    checkStripe();
}