import moment from 'moment';
import flatpickr from 'flatpickr';

// DOM Elements
const elements = {
    cancelReasonSelect: document.querySelector('#id_cancel_reason, #id_cancel-cancel_reason'),
    cancelExtraInput: document.querySelector('#id_cancel_extra, #id_cancel-cancel_extra'),
    cancelWhenSelect: document.querySelector('#id_cancel_when, #id_cancel-cancel_when'),
    cancelDateInput: document.querySelector('#id_cancel_date, #id_cancel-cancel_date'),
    cancelDateWrapper: document.querySelector('#cancel_date, #cancel_cancel-date'),
    cancelSummary: document.querySelector('#cancel_summary, #cancel_cancel-summary'),
    subReasonSelect: document.querySelector('#id_cancel_sub_reason, #id_cancel-cancel_sub_reason'),
    subReasonLabel: document.querySelector('label[for="id_cancel_sub_reason"], label[for="id_cancel-cancel_sub_reason"]'),
    subReasonContainer: document.querySelector('#id_cancel_sub_reason, #id_cancel-cancel_sub_reason') ? document.querySelector('#id_cancel_sub_reason, #id_cancel-cancel_sub_reason').closest('.form-group') : null,
    subReasonOptions: document.querySelectorAll('#id_cancel_sub_reason option, #id_cancel-cancel_sub_reason option')
};

// Initialize Sub Reason Options
export const initializeSubReasonOptions = (validSubReasons) => {
    const updateSubReasonOptions = () => {
        const selected = elements.cancelReasonSelect.value;
        const subReasons = validSubReasons[selected] || [];
        const isValidReason = subReasons.length > 0;

        if (elements.subReasonContainer) {
            elements.subReasonContainer.classList.toggle('d-none', !isValidReason);
        }
        if (elements.subReasonSelect) {
            elements.subReasonSelect.required = isValidReason;
        }
        elements.subReasonLabel.textContent = {
            'performance': 'Performance issue',
            'missing': 'Missing services',
            'price': 'Reason',
        }[selected] || 'Sub-reason (optional)';

        elements.subReasonOptions.forEach(option => {
            option.hidden = !subReasons.includes(option.value);
        });
    };

    const updateLabel = (isOther, reason) => {
        const label = elements.cancelExtraInput.parentElement.querySelector('[for="id_cancel-cancel_extra"]');
        const smallTag = label.querySelector('.text-muted');
        label.textContent = isOther ? {
            'other': 'Please share more information',
            'performance': 'What performance issue did you experience?',
            'missing': 'What services were missing?',
            'price': 'Please share more information',
        }[reason] || 'Please share more information' : 'Additional information';
        label.appendChild(smallTag);
        elements.cancelExtraInput.parentElement.querySelector('.text-muted').hidden = isOther;
        elements.cancelExtraInput.required = isOther;
    };

    const handleCancelReasonChange = () => {
        updateSubReasonOptions();
        const isOther = elements.cancelReasonSelect.value === 'other';
        updateLabel(isOther, elements.cancelReasonSelect.value);
    };

    const handleSubReasonChange = () => {
        const isOther = elements.subReasonSelect.value === 'other';
        updateLabel(isOther, elements.cancelReasonSelect.value);
    };

    elements.cancelReasonSelect.addEventListener('change', () => {
        // Reset all fields to default values
        elements.subReasonSelect.value = "";
        elements.cancelExtraInput.value = "";
        elements.cancelWhenSelect.value = "";
        elements.cancelDateInput.value = "";
        elements.cancelSummary.textContent = "";
        elements.cancelDateWrapper.classList.remove('show');
        elements.cancelDateWrapper.classList.add('hide');
        handleCancelReasonChange();
    });

    elements.subReasonSelect.addEventListener('change', handleSubReasonChange);
    handleCancelReasonChange();
};

// Force Choose When Moving
const forceChooseWhenMoving = () => {
    const isOther = elements.cancelReasonSelect.value === "other";
    elements.cancelWhenSelect.querySelectorAll('option').forEach(option => {
        option.disabled = !elements.cancelReasonSelect.value;
    });
    if (elements.cancelExtraInput.parentElement.querySelector('.text-muted')) {
        elements.cancelExtraInput.parentElement.querySelector('.text-muted').hidden = isOther;
    }
    elements.cancelExtraInput.required = isOther;
};

// Toggle Cancel Date Chooser Visibility
const toggleCancelDateChooserVisibility = () => {
    const isChoose = elements.cancelWhenSelect.value === "choose";
    elements.cancelDateWrapper.classList.toggle('show', isChoose);
    elements.cancelDateWrapper.classList.toggle('hide', !isChoose);
    elements.cancelDateInput.parentElement.querySelector('.text-muted').hidden = isChoose;
};

// Update Cancel Summary
const updateCancelSummary = () => {
    let summary = "";
    if (elements.cancelWhenSelect.value === "now") {
        summary = "Cancellation will take effect <b>immediately.</b> This cannot be undone.";
    } else if (elements.cancelWhenSelect.value === "choose" && elements.cancelDateInput.value) {
        const date = moment(elements.cancelDateInput.value).format('MMM Do, YYYY');
        summary = `Cancellation will take effect the morning of <b>${date}</b>`;
    } else if (elements.cancelWhenSelect.value === "end_of_period") {
        const periodEndDate = elements.cancelSummary.dataset.billingPeriodEnd;
        if (periodEndDate) {
            summary = `Cancellation will take effect at the end of the billing period which is <b>${periodEndDate}</b>`;
        }
    }
    elements.cancelSummary.innerHTML = summary;
};

// Event Listeners
if (elements.cancelReasonSelect && elements.cancelWhenSelect) {
    elements.cancelReasonSelect.addEventListener('change', forceChooseWhenMoving);
    document.addEventListener('DOMContentLoaded', forceChooseWhenMoving);
}

if (elements.cancelWhenSelect && elements.cancelDateInput && elements.cancelDateWrapper) {
    elements.cancelWhenSelect.addEventListener('change', toggleCancelDateChooserVisibility);
    document.addEventListener("DOMContentLoaded", toggleCancelDateChooserVisibility);
}

if (elements.cancelDateInput) {
    const today = moment.utc().hour(0).minute(0).second(0);
    const min = elements.cancelDateInput.min || today.add(1, "days").format("YYYY-MM-DD");
    const max = elements.cancelDateInput.max || today.add(120, "days").format("YYYY-MM-DD");

    flatpickr(elements.cancelDateInput, {
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "F j, Y",
        minDate: min,
        maxDate: max,
        disableMobile: true,
    });
}

if (elements.cancelReasonSelect && elements.cancelWhenSelect && elements.cancelDateInput && elements.cancelSummary) {
    elements.cancelReasonSelect.addEventListener('change', updateCancelSummary);
    elements.cancelWhenSelect.addEventListener('change', updateCancelSummary);
    elements.cancelDateInput.addEventListener('change', updateCancelSummary);
}
