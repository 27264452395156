document.addEventListener('DOMContentLoaded', function () {
    // Password field toggler
    const passwordTogglers = document.querySelectorAll('.password-field__toggle');

    passwordTogglers.forEach((toggler) => {
        toggler.addEventListener('change', () => {
            const passwordField = toggler.closest('.password-field');
            const passwordInput = passwordField.querySelector('input.password-field__input');
            passwordInput.type = toggler.checked ? 'text' : 'password';
        });
    });

    // Show passphrase
    const showPassphraseButton = document.getElementById('js-show-passphrase');
    const passphrase = document.getElementById('customer-network-passphrase');

    if (showPassphraseButton && passphrase) {
        showPassphraseButton.addEventListener('click', () => {
            passphrase.innerHTML = "<pre class='m-0 pt-1'>" + passphrase.dataset.passphrase + "</pre>";
        });
    }

    // Prevent double submit
    function disable_buttons(form, message) {
        const buttons = document.querySelectorAll('button[type="submit"], input[type="submit"]');
        for (const button of buttons) {
            button.disabled = true;
            if (message) {
                button.innerHTML = message;
            }
        }
    }

    const disableForms = document.querySelectorAll('.js-prevent-double-submit');
    for (const form of disableForms) {
        const message = form.dataset.message;
        let submitTimer;

        form.addEventListener('submit', function(e) {
            e.preventDefault();
            disable_buttons(this, message);
            if (submitTimer) {
                clearTimeout(submitTimer);
            }
            submitTimer = setTimeout(() => {
                this.submit();
            }, 0);
        });
    }
});
