// Reveal text word-by-word
//
// Usage:
//
//      <div data-text-reveal="words" data-text-reveal-start="top 80%" data-text-reveal-end="top 40%">
//
// data-text-reveal: The type of reveal: 'lines, 'words' or 'chars'.
// data-text-reveal-start: When to trigger the reveal. Default: top 80%
// data-text-reveal-end: When to end the reveal. Default: top 50%
// data-text-reveal-debug: Paint debug markers to help with positioning. Default: false
//
// start/end: https://greensock.com/docs/v3/Plugins/ScrollTrigger



import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type';

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', function() {
    const elements = document.querySelectorAll('[data-text-reveal]');

    for (const element of elements) {
        const types = element.dataset.textReveal;
        const start = element.dataset.textRevealStart || "top 80%";
        const end = element.dataset.textRevealEnd || "top 40%";
        const debug = ['true', '1', 'yes', 'on'].includes(element.dataset.textRevealDebug) || false;

        if (!['lines', 'words', 'chars'].includes(types)) {
            console.error(`data-text-reveal must be one of 'lines', 'words' or 'chars'.`);
            continue;
        }

        // Break the text into individual word elements
        const text = new SplitType(element, { types: types });
        let splitElements;
        switch (types) {
            case 'lines':
                splitElements = text.lines;
                break;
            case 'words':
                splitElements = text.words;
                break;
            case 'chars':
                splitElements = text.chars;
                break;
        }

        // Create a timeline tied to the scroll position
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                start: start,
                end: end,
                markers: debug,
                scrub: true
            }
        });

        // Add each split element to the timeline to animate from 0.2 to 1 opacity. Duration and stagger don't matter
        // since the timeline is tied to the scroll position, but without them the animation is instant.
        tl.fromTo(splitElements,
            {
                opacity: 0.2,
            }, {
                opacity: 1,
                duration: 1,
                stagger: 1,
            }
        );
    }
});
