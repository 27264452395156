// Support for the Signup Form
//
// This is a single-input address-matching form using Google Places API. It makes a best effort to be additive... in
// other words it should work fine even if the JS or autocomplete fail completely. It submits to the
// CheckAvailabilityView with "prefill" set to true, which will prefill the form with the address instead of actually
// searching for it. User needs to verify the information then click submit.
//
// The main goal of this is to allow a single address field with autocomplete submit to the CheckAvailabilityView form
// so the user can verify that all of the information is correct before submitting.
//
// TODO: replace with Place Autocomplete (new) when it's generally available and not experimental
//       https://developers.google.com/maps/documentation/javascript/place-autocomplete-new

export async function initSignupForm() {
    const { Autocomplete } = await google.maps.importLibrary("places");

    const elements = document.querySelectorAll("[data-signup-form]");

    for (const form of elements) {
        const input = form.querySelector("[data-signup-form-input]");

        if (!input) {
            console.error("No input found for Signup Form, create a child <input data-signup-form-input>", form);
            continue;
        }

        const options = {
            types: ["address"],
            fields: ["address_components"],
            componentRestrictions: {country: 'us'},
        };

        const ac = new Autocomplete(input, options);
        ac.addListener('place_changed', () => onPlaceChanged(form, input, ac));

        // Override enter key
        input.addEventListener('keypress', (e) => interceptEnterKey(e, form, input, ac));

        // console.log('Signup form initialized', form);
    }
}

function interceptEnterKey(e, form, input, ac) {
    if (e.key === "Enter") {
        e.preventDefault();
    }
}

const ac_trans = {
    'street_number': {part: 'short_name', target: 'number'},
    'route': {part: 'long_name', target: 'address'},
    'locality': {part: 'long_name', target: 'city'},
    'sublocality_level_1': {part: 'long_name', target: 'sublocality'},
    'neighborhood': {part: 'long_name', target: 'neighborhood'},
    'administrative_area_level_1': {part: 'long_name', target: 'state'},
    'postal_code': {part: 'short_name', target: 'zip_code'}
};

function onPlaceChanged(form, input, ac) {
    const place = ac.getPlace();
    console.log("onPlaceChanged", place);

    // Make sure we got a place and it has address_components, otherwise just submit with just the address_line1 field
    if (!place || !place.address_components) {
        console.warn("No place or address_components", place);
        submitForm(form, input, {address_line1: input.value});
        return;
    }

    // Iterate through all address components and pull out components based on the ac_trans configuration
    const translated = {};
    for (let address_component of place.address_components) {
        const type = address_component.types[0];
        const trans = ac_trans[type];
        if (trans) {
            translated[trans.target] = address_component[trans.part]
        }
    }

    // Now look at what translated parts we got and whittle that down to just the address parts we care about
    const parts = {};

    if (translated.number && translated.address) {
        parts.address_line1 = `${translated.number} ${translated.address}`;
    } else if (translated.address) {
        parts.address_line1 = translated.address;
    } else {
        parts.address_line1 = input.value;
    }
    if (translated.city) {
        parts.city = translated.city;
    } else if (translated.sublocality) {
        parts.city = translated.sublocality;
    } else if (translated.neighborhood) {
        parts.city = translated.neighborhood;
    }
    if (translated.state) {
        parts.state = translated.state;
    }
    if (translated.zip_code) {
        parts.zip_code = translated.zip_code;
    }

    submitForm(form, input, parts);
}

function submitForm(form, input, parts) {
    // Add all the other parts of the address to the form and submit it.

    if (!parts.address_line1) {
        console.error("No address_line1", parts);
        return;
    }

    if (input) {
        input.value = parts.address_line1;
    }

    for (const key in parts) {
        if (key === "address_line1") {
            continue;
        }
        const input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", key);
        input.setAttribute("value", parts[key]);
        form.appendChild(input);
    }

    form.submit();
}

window.addEventListener('DOMContentLoaded', initSignupForm);
