import moment from 'moment';
import flatpickr from 'flatpickr';

const auto_activation_date_input = document.getElementById('id_auto_activation_date') ||
    document.getElementById('id_installation-auto_activation_date');

if (auto_activation_date_input) {
    const today = moment.utc().hour(0).minute(0).second(0);
    let min = auto_activation_date_input.min || today.add(1, "days").format("YYYY-MM-DD");
    let max = auto_activation_date_input.max || today.add(120, "days").format("YYYY-MM-DD");

    flatpickr(auto_activation_date_input, {
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "F j, Y",
        minDate: min,
        maxDate: max,
        disableMobile: true,
    });

    window.auto_activation_date_input = auto_activation_date_input;
}

