document.addEventListener('DOMContentLoaded', function () {
  const inputLabels = document.querySelectorAll("[data-service-box]");
  
  if( inputLabels.length == 0 ) {
    return;
  }

  function removeAllSelectedPlanClass() {
      inputLabels.forEach((label) => {
          label.classList.remove('selected-plan')
      })
  }

  inputLabels.forEach((label) => {
      label.addEventListener('click', () => {
          removeAllSelectedPlanClass();
          label.classList.add('selected-plan')
      })
  })
})