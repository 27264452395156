// Toggles a fullscreen menu to be "open", with support for transitions.
//
// It does this by adding the "open" class to the element, and removing it when closed. It will also add the
// "transitioning" class when the menu is toggled, and remove it after the animation is completed.
//
// Additionally, the body will have its overflow set to "hidden" when the menu is open, and "visible" when closed to
// prevent phantom scrolling behind the menu and cause the scrollbar to appear/disappear.
//
// Usage:
//
//     <button data-toggler-target="#id-of-element-to-toggle" />
//
// data-toggler-target: The selector of the element to toggle, which must work with `document.querySelector()`. e.g.
//
//     #id-of-element-to-toggle
//     .class-of-element-to-toggle
//     [data-attribute-of-element-to-toggle]
//

document.addEventListener('DOMContentLoaded', function() {
    const elements = document.querySelectorAll('[data-menu-toggler-target]');

    [...elements].forEach(function(element) {
        const target = document.querySelector(element.dataset.menuTogglerTarget);

        if (!target) {
            console.error(`No element found with selector ${element.dataset.menuTogglerTarget}`);
            return;
        }

        // console.debug("Menu toggler on", element, "toggling", target);

        element.addEventListener('click', function() {
            // Add the 'transitioning' class to animate it.
            target.classList.add('transitioning');

            // Toggle us either open or not
            target.classList.toggle('open');

            // If we're open, set the body to not scroll
            if(target.classList.contains('open')) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'visible';
            }

            // Remove the 'transitioning' class once the animation completes
            const duration = window.getComputedStyle(target).transitionDuration;
            const factor = duration.indexOf('ms') !== -1 ? 1 : 1000;
            const duration_ms = parseInt(parseFloat(duration) * factor, 10);
            setTimeout(function() {
                target.classList.remove('transitioning');
            }, duration_ms);
        });
    });
});
